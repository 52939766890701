import React, { ReactNode } from "react"
import { AppProvider as AppProviderV1 } from "../components/context/context"
import { GeneralSettingsProvider } from "./GeneralSettingsContext/GeneralSettingsContext"

interface AppProviderProps {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <GeneralSettingsProvider>
        <AppProviderV1>{children}</AppProviderV1>
    </GeneralSettingsProvider>
  )
}
