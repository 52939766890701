import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

const GeneralSettingsContext = createContext()

const GeneralSettingsProvider = ({ children }) => {
  const data = useStaticQuery(getSettings)

  const { headerLogo, headerLogoSticky, footerLogo, mainNav, socialIcons, events, churches } = data.contentfulSettings

  return (
    <GeneralSettingsContext.Provider
      value={{
        headerLogo,
        headerLogoSticky,
        footerLogo,
        mainNav,
        socialIcons,
        events,
        churches
      }}
    >
      {children}
    </GeneralSettingsContext.Provider>
  )
}

export const getSettings = graphql`
  query SettingsQuery {
    contentfulSettings {
      headerLogo {
        gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
      headerLogoSticky {
        gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
      footerLogo {
        gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
      mainNav {
        ...navItem
      }
      socialIcons {
        ...SocialLinkFragment
      }
      events {
        ...EventFragment
      }
      churches {
        ...ChurchFragment
      }
    }
  }
  `

export { GeneralSettingsProvider, GeneralSettingsContext }
